<template>
  <b-modal
    id="general-sheet-template-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="sheet?.id">Editar ficha personalizada</span>
        <span v-else>Adicionar nova ficha personalizada</span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <validation-observer v-slot="{ invalid }">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <validation-provider name="name" rules="required">
                <label for="name">Nome da ficha</label>
                <b-input
                  id="name"
                  autocomplete="off"
                  placeholder="Descrever"
                  v-model="form.name"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label for="description">
                Descrição <span class="help">(opcional)</span>
              </label>
              <TextArea
                id="description"
                :value="form.description"
                placeholder="Descrição"
                @input="value => (form.description = value)"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group>
              <div class="file-atach md-content">
                <div
                  class="file-container"
                  :class="{ 'without-file': !file.name }"
                  @dragover.prevent
                  @drop.prevent
                >
                  <div @drop="dragFile" class="drag-area mt-2">
                    <div v-if="!file?.name">
                      <p>
                        Arraste o arquivo que será usado para impressão
                        (opcional)
                      </p>
                      <div class="separator">
                        <HorizontalLine /> ou <HorizontalLine />
                      </div>

                      <label for="add-file-input" class="add-btn">
                        Anexar arquivos
                      </label>
                      <input
                        id="add-file-input"
                        type="file"
                        accept=".jpg, .png, .pdf, .jpeg, .jps, .tiff"
                        @change="addFile"
                      />
                    </div>
                    <div class="file" v-else>
                      <div>
                        <Pdf v-if="file.type === 'application/pdf'" />
                        <Files v-else />
                        {{ file.name }}
                      </div>
                      <Close class="close" @click="removeFile" />
                    </div>
                    <b-progress
                      class="progress"
                      v-if="file?.name && progress"
                      :max="100"
                      show-progress
                      :animated="progress !== 100"
                      :variant="!error ? 'info' : 'danger'"
                    >
                      <b-progress-bar :value="progress">
                        <span>
                          <strong>{{ progress }}%</strong>
                        </span>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <div class="wrapper-button">
          <b-button
            v-if="sheet?.id"
            :disabled="invalid"
            variant="primary"
            size="lg"
            @click="updateGeneralSheetTemplate"
          >
            Atualizar ficha
          </b-button>
          <b-button
            v-else
            variant="primary"
            :disabled="invalid"
            size="lg"
            @click="createGeneralSheetTemplate"
          >
            Salvar ficha
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'GeneralSheetTemplateModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    TextArea: () => import('@/components/General/TextArea'),
    HorizontalLine: () => import('@/assets/icons/line.svg'),
    Pdf: () => import('@/assets/icons/pdf.svg'),
    Files: () => import('@/assets/icons/files.svg')
  },
  props: {
    sheet: {
      type: Object,
      default: () => ({})
    },
    clinicId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    form: {
      name: null,
      description: null,
      file: null
    },
    file: {},
    progress: 0,
    error: false
  }),
  methods: {
    async createGeneralSheetTemplate() {
      const isLoading = this.$loading.show()
      try {
        const dataForm = new FormData()
        dataForm.append('clinic_id', this.clinicId)
        dataForm.append('name', this.form.name)
        dataForm.append('description', this.form.description)
        dataForm.append('file', this.file)

        const { data } = await this.api.createGeneralSheetTemplate(dataForm, {
          onUploadProgress: event => {
            this.progress = Math.round((event.loaded * 100) / event.total)
          }
        })
        this.$toast.success('Ficha cadastrada com sucesso!')
        this.$emit('createGeneralSheetTemplate', data)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateGeneralSheetTemplate() {
      const isLoading = this.$loading.show()
      try {
        const dataForm = new FormData()

        this.helpers.buildFormData(dataForm, {
          name: this.form.name,
          description: this.form.description,
          file: this.file
        })

        const { data } = await this.api.updateGeneralSheetTemplate(
          this.sheet.id,
          dataForm,
          {
            onUploadProgress: event => {
              this.progress = Math.round((event.loaded * 100) / event.total)
            }
          }
        )
        this.$toast.success('Ficha atualizada com sucesso!')
        this.$emit('updateGeneralSheetTemplate', {
          ...data,
          active: Boolean(data.active)
        })
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    closeModal() {
      this.$bvModal.hide('general-sheet-template-modal')
      this.$emit('closeModal')
      this.clearData()
    },
    clearData() {
      Object.entries(this.form).forEach(([key]) => {
        this.form[key] = null
      })
      this.file = {}
      this.progress = 0
    },
    addFile(e) {
      this.progress = null
      this.error = false
      this.file = e.target.files[0]
    },
    dragFile(e) {
      this.progress = null
      this.error = false
      const file = e.dataTransfer.files[0]
      const fileType = file.type
      this.fileExtension = fileType.split('/')[1]
      let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
      nameExt = nameExt.length > 1 ? nameExt[1] : ''
      if (
        !['pdf', 'jpg', 'jpeg', 'png', 'jps', 'application/pdf', 'tiff'].includes(
          fileType || nameExt
        )
      ) {
        return this.$toast.error('Formato de arquivo não suportado!')
      }
      this.file = e.dataTransfer.files[0]
    },
    removeFile() {
      this.progress = null
      this.error = false
      this.file = {}
    }
  },
  watch: {
    sheet: {
      handler(value) {
        if (value?.id) {
          this.form = {
            ...this.form,
            ...value
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
#general-sheet-template-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .file-atach {
      .file-container {
        height: 100px;
        background: transparent;
        border-top: 1px solid var(--neutral-200);

        &.without-file {
          height: 160px;
          border: 1px dashed var(--blue-500);
          box-sizing: border-box;
          border-radius: 8px;
          background: var(--neutral-100);
        }

        .drag-area {
          width: 100%;
          height: 100%;
          padding: 20px;
          font-weight: bold;
          font-size: 16px;
          line-height: 130%;
          color: var(--type-active);
          text-align: center;

          .separator {
            margin: 12px 0px;
          }

          .add-btn {
            background: var(--blue-700);
            border-radius: 8px;
            color: var(--neutral-000);
            padding: 8px 15px;
            cursor: pointer;

            font-family: Nunito Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
          }

          #add-file-input {
            display: none;
          }

          .file {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            align-content: center;

            svg {
              width: 42px !important;
              height: 42px !important;
            }

            .close {
              width: 20px !important;
              height: 20px !important;
            }
          }
        }
      }
    }

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
